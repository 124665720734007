var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-content-end" }, [
    _vm.isLoaded
      ? _c("div", {
          staticClass:
            "h-100 d-flex justify-content-end align-items-center position-relative",
        })
      : _c(
          "div",
          {
            staticClass: "spinner-border spinner-border-sm text-light me-2",
            attrs: { role: "status" },
          },
          [_c("span", { staticClass: "sr-only" })]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }