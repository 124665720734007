var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "quote-request-modal",
        "data-bs-backdrop": "static",
        "data-bs-keyboard": "false",
        tabindex: "-1",
        "aria-labelledby": "cartModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-lg" },
        [
          _c("div", { staticClass: "modal-content border-0" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body pt-4" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", {}, [
                      _c("div", { staticClass: "row" }, [
                        _c("iframe", {
                          staticStyle: {
                            "min-width": "100%",
                            "max-width": "100%",
                            height: "539px",
                            border: "none",
                          },
                          attrs: {
                            id: "JotFormIFrame-250511436347351",
                            title: "Formulaire de contact",
                            onload: "window.parent.scrollTo(0,0)",
                            allowtransparency: "true",
                            allow:
                              "geolocation; microphone; camera; fullscreen",
                            src: _vm.jotformUrl,
                            frameborder: "0",
                            scrolling: "no",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header border-0" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "cartModalLabel" } }),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }