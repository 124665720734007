<template>
  <div class="d-flex justify-content-end">
    <div
      v-cloak
      v-if="isLoaded"
      class="h-100 d-flex justify-content-end align-items-center position-relative"
    >
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm text-light me-2"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      sideNavigationOpen: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapFields('app', ['isCardLoginIsShowing', 'user', 'isAuthenticated']),
    espaceElearningLink() {
      return '/e-learning-page'
    },
  },
  mounted() {
    this.$bus.$on('authentication.changed', () => {
      this.isLoaded = true
    })
  },
  destroyed() {},
  methods: {
    showLoginCard() {
      this.isCardLoginIsShowing = !this.isCardLoginIsShowing
      if (this.isCardLoginIsShowing) {
        window.dataLayer.push({
          event: 'se_connecter',
          category: 'login',
          action: 'display',
          label: 'connect',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cart {
  position: relative;
  cursor: pointer;

  &-icon {
    cursor: pointer;
    width: 1.498rem !important;
    height: 1.25rem !important;
    margin-left: 1rem;
  }
}

.cart-items-number {
  position: absolute;
  top: 0;
  left: 80%;
  width: 14px;
  height: 14px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: red;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  font-size: 0.813rem;

  .number {
    font-size: 0.6rem;
  }
}
</style>
